<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-5">
          <div class="d-flex justify-space-between">
            <div class="text-h6 accent--text font-weight-bold">
              {{ school.name }}
            </div>

            <v-btn
              class="primary--text font-weight-bold"
              text
              @click="toSchoolDevice"
            >
              設備管理
              <v-icon color="primary" class="mb-1"
                >mdi-arrow-right-thick</v-icon
              >
            </v-btn>
          </div>
          <div
            class="mt-4 mb-2 text-body-2 font-weight-bold accent--text d-flex"
          >
            <div>
              <v-icon color="success">mdi-circle-slice-8</v-icon>
              連線中
            </div>
            <div class="mx-2">
              <v-icon color="error">mdi-circle-slice-8</v-icon>
              尚未連線
            </div>
            <div>
              <v-icon color="warning">mdi-circle-slice-8</v-icon>
              最後更新時間超過 20 分鐘
            </div>
          </div>
          <v-sheet
            class="accent--text font-weight-bold mt-4 mb-4 pa-4"
            color="ems-light-periwinkle"
            width="100%"
          >
            學校裝置
          </v-sheet>
          <v-card class="pa-4">
            <v-data-table
              :headers="headers"
              :items="school.devices"
              hide-default-footer
              :items-per-page="-1"
              loading-text="載入中..."
              no-data-text="( 無資料 )"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-icon :color="judgeStatus(item.updateTs)"
                  >mdi-circle-slice-8</v-icon
                >
              </template>
            </v-data-table>
          </v-card>
          <v-sheet
            class="
              accent--text
              font-weight-bold
              mt-10
              mb-4
              pa-4
              d-flex
              align-center
              justify-space-between
            "
            color="ems-light-periwinkle"
            width="100%"
          >
            <div>教室列表</div>
            <v-sheet width="300px" color="transparent">
              <v-select
                :items="school.child"
                v-model="selectedClass"
                item-text="name"
                clearable
                prepend-inner-icon="mdi-filter"
                outlined
                hide-details=""
                rounded
                color="accent"
                placeholder="選擇教室"
                dense
              />
            </v-sheet>
          </v-sheet>
          <v-card v-for="classroom in filterClass" class="mb-5 pa-4">
            <div class="accent--text font-weight-bold ml-2 mb-2">
              {{ classroom.name }}
            </div>
            <v-data-table
              :headers="headers"
              :items="classroom.devices"
              hide-default-footer
              :items-per-page="-1"
              loading-text="載入中..."
              no-data-text="( 無資料 )"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-icon :color="judgeStatus(item.updateTs)"
                  >mdi-circle-slice-8</v-icon
                >
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      fab
      color="ems-blue-grey-2 "
      class="goTopBtn"
      @click="$vuetify.goTo(0)"
    >
      <v-icon color="white">mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Device from '@/api/ems/Device'
import { mapActions } from 'vuex'

export default {
  props: {
    schoolId: {
      type: String
    }
  },
  data() {
    return {
      school: {},
      headers: [
        { text: '裝置名稱', value: 'name' },
        {
          text: '狀態',
          value: 'status',
          width: '150',
          sortable: false,
          align: 'center'
        }
      ],
      selectedClass: ''
    }
  },
  watch: {
    schoolId(val) {
      if (val) this.getSchoolDeviceStatus()
    }
  },
  computed: {
    filterClass() {
      return this.selectedClass
        ? this.school?.child?.filter(
            (classroom) => classroom.name === this.selectedClass
          )
        : this.school.child
    }
  },
  mounted() {
    if (this.schoolId) {
      this.getSchoolDeviceStatus()
    }
  },
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    async getSchoolDeviceStatus() {
      try {
        this.setOverlayLoading(true)
        const { data } = await Device.getSchoolDeviceStatus(this.schoolId)
        console.log(data)
        this.school = data
      } catch (error) {
        console.error(error)
      } finally {
        this.setOverlayLoading(false)
      }
    },
    judgeStatus(updateTs) {
      if (!updateTs) return 'error'
      else {
        const current = new Date()
        const updateTime = new Date(updateTs)
        if (current - updateTime < 20 * 60 * 1000) return 'success'
        else return 'warning'
      }
    },
    toSchoolDevice() {
      if (this.schoolId)
        this.$router.push({ name: 'Damaged', params: { tagId: this.schoolId } })
    }
  }
}
</script>

<style lang="scss" scoped>
.goTopBtn {
  bottom: 10px;
  right: 10px;
  position: fixed;
}
</style>
